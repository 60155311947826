import {
  Box,
  ChakraIcons,
  Checkbox,
  Flex,
  Paginate,
  Select,
  Slider,
  Text
} from '@stars-ecom/shared-atoms-ui'
import {
  CollectionHeader,
  DataLayerUtils,
  ProductThumbnail,
  WebsiteContext
} from '@stars-ecom/shared-organisms-ui'
import ceil from 'lodash/ceil'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import last from 'lodash/last'
import max from 'lodash/max'
import min from 'lodash/min'
import orderBy from 'lodash/orderBy'
import set from 'lodash/set'
import slice from 'lodash/slice'
import split from 'lodash/split'
import startsWith from 'lodash/startsWith'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import { siteFormatter } from '../utils/metaTitleFormatter'

const TVShowPage = (props) => {
  const getPage = () => {
    const parts = location?.pathname?.split('/')
    if (isNaN(last(parts) * 1)) {
      return 1
    }
    return last(parts) * 1
  }
  const { pageContext, location } = props
  const { collection, products: _products = [], nbProductsPerPage } = pageContext
  const websiteContext = useContext(WebsiteContext)
  const [sortCrit, setSortCrit] = useState('default')
  const [currentPage, setCurrentPage] = useState(getPage())
  const [pageCount, setPageCount] = useState(ceil(collection?.totalProducts / nbProductsPerPage))
  const [priceRange, setPriceRange] = useState([])
  const [facets, setFacets] = useState([])
  const [selectedFacets, setSelectedFacets] = useState([])
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [minPrice, setMinPrice] = useState(0)
  const [maxPrice, setMaxPrice] = useState(200)
  const [products, setProducts] = useState(_products)
  const [filteredProducts, setFilteredProducts] = useState(_products)
  const [sortedProducts, setSortedProducts] = useState(_products)
  const date = collection?.name?.match(/\d*\/\d+$/)

  useEffect(() => {
    DataLayerUtils.updateDataLayer({ nbProduit: collection?.totalProducts })
  }, [collection])

  const getParam = (key) => {
    const search = location?.search?.replace('?', '').split('&')
    for (const s of search) {
      if (startsWith(s, key)) {
        return s.split('=')[1]
      }
    }
    return null
  }
  useEffect(() => {
    setSortCrit(getParam('sort') || 'default')
    setCurrentPage(getPage())
  }, [location])

  const getPrice = (p) => min(p?.variants?.map((v) => v?.priceWithTax))

  const hrefBuilder = (e = 1) => {
    let sort = ''
    if (!(sortCrit === 'tvShow-desc' || sortCrit === 'default' || !sortCrit)) {
      sort = `?sort=${sortCrit}`
    }
    e = e * 1
    if (e === 1) {
      return `${collection.path}${sort}`
    }
    const path = `${collection.path}/${e}${sort}`
    return path
  }

  const getPriceRange = () => {
    const minValue = min(products.map(getPrice)) / 100
    const maxValue = max(products.map(getPrice)) / 100
    setMinPrice(minValue - 1)
    setMaxPrice(maxValue + 1)
    const diff = maxValue - minValue

    const result = [
      Math.round(minValue - 1),
      Math.round(minValue + diff / 5),
      Math.round(minValue + (2 * diff) / 5),
      Math.round(minValue + (3 * diff) / 5),
      Math.round(minValue + (4 * diff) / 5),
      Math.round(maxValue + 1)
    ]
    return result
  }

  const sortProducts = async () => {
    setPageCount(ceil(filteredProducts.length / nbProductsPerPage))
    switch (sortCrit) {
      case 'name-asc':
        setSortedProducts(
          slice(
            orderBy(filteredProducts, ['name'], ['asc']),
            (currentPage - 1) * nbProductsPerPage,
            currentPage * nbProductsPerPage
          )
        )
        break
      case 'name-desc':
        setSortedProducts(
          slice(
            orderBy(filteredProducts, ['name'], ['desc']),
            (currentPage - 1) * nbProductsPerPage,
            currentPage * nbProductsPerPage
          )
        )
        break
      case 'price-asc':
        setSortedProducts(
          slice(
            orderBy(filteredProducts, [getPrice], ['asc']),
            (currentPage - 1) * nbProductsPerPage,
            currentPage * nbProductsPerPage
          )
        )
        break
      case 'price-desc':
        setSortedProducts(
          slice(
            orderBy(filteredProducts, [getPrice], ['desc']),
            (currentPage - 1) * nbProductsPerPage,
            currentPage * nbProductsPerPage
          )
        )
        break
      default:
        setSortedProducts(
          slice(
            filteredProducts,
            (currentPage - 1) * nbProductsPerPage,
            currentPage * nbProductsPerPage
          )
        )
    }
  }

  useEffect(() => {
    sortProducts()
  }, [sortCrit, currentPage, filteredProducts])

  const filterProducts = () => {
    const filterFunction = (p) => {
      const priceFilter = getPrice(p) >= minPrice * 100 && getPrice(p) <= maxPrice * 100
      const family = p.customFields?.secondaryHierarchy
      let facetFilter = false

      if (isEmpty(selectedFacets)) {
        facetFilter = true
      } else if (family?.length) {
        const level = family?.length - 1
        const label = get(split(family[level], '#'), '1') || family[level]

        if (selectedFacets.includes(label)) {
          facetFilter = true
        }
      }
      return priceFilter && facetFilter
    }
    setFilteredProducts(products.filter(filterFunction))
  }

  useEffect(() => {
    filterProducts()
  }, [products, minPrice, maxPrice, selectedFacets])

  const getData = () => {
    fetch(`/tvshow/${collection.id}.json`)
      .then((res) => res.json())
      .then(
        (res) => {
          setProducts(res.products)
        },
        (e) => {
          console.error(e)
        }
      )
  }

  useEffect(() => {
    setPriceRange(getPriceRange)
    getFacets()
  }, [products])

  useEffect(() => {
    getData()
  }, [])

  const doOrdering = (e) => {
    setSortCrit(e?.target?.value)
  }

  const showFilters = () => {
    setFiltersVisible(!filtersVisible)
  }

  const handleChangePriceRange = (values) => {
    setMinPrice(values[0])
    setMaxPrice(values[1])
  }

  useEffect(() => {
    getFacets()
  }, [maxPrice, minPrice])

  useEffect(() => {
    setTimeout(
      (list) => {
        DataLayerUtils.addProductImpression({
          command: 'productImpression',
          productList: list,
          listName: `Page émission TV du ${get(date, '0')}`
        })
      },
      100,
      sortedProducts
    )
  }, [currentPage, sortCrit, selectedFacets])
  const getFacets = () => {
    const result = []

    for (const product of products) {
      let labels = product?.customFields?.secondaryHierarchy

      if (labels?.length) {
        let level = labels?.length - 1
        const label = get(split(labels[level], '#'), '1') || labels[level]
        const price = getPrice(product)
        const increment = price >= minPrice * 100 && price <= maxPrice * 100 ? 1 : 0

        const found = result.find((t) => t.label === label)
        if (found) {
          set(found, 'nb', found.nb + increment)
          set(found, 'minPrice', min([found.minPrice, price]))
          set(found, 'maxPrice', max([found.minPrice, price]))
        } else {
          result.push({ label, nb: increment, minPrice: price, maxPrice: price })
        }
      }
    }
    setFacets(orderBy(result, ['label'], ['asc']))
  }

  const handleFacetChange = (v) => {
    if (selectedFacets.includes(v)) {
      setSelectedFacets(selectedFacets.filter((f) => f !== v))
    } else {
      setSelectedFacets([...selectedFacets, v])
    }
  }

  const FacetDisplay = ({ facet }) => {
    return (
      <Flex width={{ base: '100%', md: '50%' }} mb={{ base: '3px', md: '6px' }}>
        <Checkbox
          isDisabled={facet.nb === 0}
          isChecked={selectedFacets.includes(facet.label)}
          value={facet.label}
          onChange={(e) => handleFacetChange(e.target.value)}
          backgroundColorChecked={websiteContext?.mainColor}
        />
        <Text
          fontSize={{ base: '12px', md: '14px' }}
          fontFamily="PT Sans, Arial, Sans-serif"
          textTransform="lowercase"
          color="#4b4b4b"
          ml="10px">{`${facet?.label} (${facet.nb})`}</Text>
      </Flex>
    )
  }

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      pb="15px"
      backgroundColor="#f6f3f1"
      w="100%">
      <Helmet>
        <meta charSet="utf-8" />
        {/* TODO Handling meta for tvshow page on ???*/}
        <meta
          name="description"
          content={`Les produits de l'émission de téléachat ${siteFormatter(
            process.env.GATSBY_API_WEBSITE
          )} du ${get(date, '0')}`}
        />
        <title>{`Les produits de l'émission de téléachat ${siteFormatter(
          process.env.GATSBY_API_WEBSITE
        )} du ${get(date, '0')}`}</title>
        <link
          rel="canonical"
          href={`${process.env.GATSBY_API_URL?.replace(/\/+$/, '')}${hrefBuilder(currentPage)}`}
        />
        {currentPage > 1 && (
          <link
            rel="prev"
            href={`${process.env.GATSBY_API_URL?.replace(/\/+$/, '')}${hrefBuilder(
              currentPage - 1
            )}`}
          />
        )}
        {currentPage < pageCount && (
          <link
            rel="next"
            href={`${process.env.GATSBY_API_URL?.replace(/\/+$/, '')}${hrefBuilder(
              currentPage + 1
            )}`}
          />
        )}
      </Helmet>
      <CollectionHeader
        collection={collection}
        type="tvShowPage"
        tvShowBannerImage={
          pageContext?.tvShowSettings?.collectionBannerImage?.image?.childImageSharp
            ?.gatsbyImageData
        }
      />
      {collection?.totalProducts > 1 && (
        <Flex
          justify="space-between"
          width="100%"
          maxWidth={{ base: '100%', md: '1000px' }}
          mt="20px"
          p={{ base: '0em 1em', md: '0em 1em', lg: '0em' }}>
          <Flex
            className="search-select-item"
            onClick={showFilters}
            w={{ base: '34%', md: '135px' }}
            h="43px"
            fontSize={{ base: '13px', md: '14px' }}
            fontFamily="PT Sans, Arial, Sans-serif"
            p="10px 20px"
            alignItems="center"
            justifyContent="space-between"
            backgroundColor="white"
            border={filtersVisible ? `1px solid ${websiteContext.mainColor}` : '1px solid #c1c1c1'}
            _hover={{ cursor: 'pointer' }}>
            <Text color={filtersVisible && websiteContext.mainColor}>Filtrer par</Text>
            <ChakraIcons.TriangleDownIcon
              color={filtersVisible && websiteContext.mainColor}
              w={2}
              h={2}
            />
          </Flex>
          <Select
            onChange={doOrdering}
            w={{ base: '64%', md: '215px' }}
            placeholder="Trier par"
            h="43px"
            fontSize={{ base: '13px', md: '14px' }}
            fontFamily="PT Sans, Arial, Sans-serif"
            backgroundColor="white"
            border="1px solid #c1c1c1"
            borderColor="#c1c1c1"
            borderRadius="0"
            _focus={{ borderColor: websiteContext.mainColor }}
            icon={<ChakraIcons.TriangleDownIcon />}
            iconSize="8"
            value={sortCrit}>
            <option value="default">Vu récemment à l&acute;émission</option>
            <option value="price-desc">Du + cher au - cher</option>
            <option value="price-asc">Du - cher au + cher</option>
            <option value="name-asc">De A à Z</option>
            <option value="name-desc">De Z à A</option>
          </Select>
        </Flex>
      )}
      {filtersVisible && (
        <Flex
          width="100%"
          justify="center"
          bg="#fff"
          border="1px"
          borderColor={{ base: '#ffffff', md: '#cfd5e3' }}
          borderTop="2px"
          borderTopColor={{ base: websiteContext?.mainColor, md: websiteContext?.mainColor }}
          mt="20px">
          <Flex width="100%" maxWidth="1000px" direction="column">
            <Flex w="190px" position="relative" mt="-30px">
              <Box
                style={{
                  position: 'relative',
                  display: 'block',
                  content: `""`,
                  border: '15px solid transparent',
                  top: '-1px',
                  left: '95px',
                  borderBottomColor: websiteContext?.mainColor
                }}></Box>
              <Box
                style={{
                  position: 'relative',
                  display: 'block',
                  content: `""`,
                  border: '15px solid transparent',
                  left: '65px',
                  top: '2px',
                  borderBottomColor: '#fff'
                }}></Box>
            </Flex>
            <Flex
              justifyContent="space-between"
              direction={{ base: 'column', md: 'row' }}
              w="100%"
              className="filterContainer"
              p={{ base: '10px 10px 15px 10px', md: '30px 0px' }}>
              <Flex
                direction="column"
                width={{ base: '95%', md: '60%' }}
                m={{ base: '0px 10px', md: '0px 10px' }}>
                <Text
                  fontWeight={700}
                  textTransform="uppercase"
                  fontSize="14px"
                  color="#333333"
                  mb="15px">
                  Sous-catégories
                </Text>
                <Flex direction={{ base: 'column', md: 'row' }} width="100%" flexWrap="wrap">
                  {facets.map((f, i) => (
                    <FacetDisplay key={`facet_${i}`} facet={f} />
                  ))}
                </Flex>
              </Flex>
              <Flex
                direction="column"
                width={{ base: '100%', md: '35%' }}
                justify="flex-start"
                align="flex-start"
                mt={{ base: '25px', md: '0px' }}>
                <Text
                  fontWeight={700}
                  textTransform="uppercase"
                  fontSize="14px"
                  color="#333333"
                  pl="15px">
                  Prix
                </Text>
                <Box className="slider-container" mt="10px" w="100%" pt="20px">
                  <Slider
                    range={priceRange}
                    handleChange={handleChangePriceRange}
                    minValue={minPrice}
                    maxValue={maxPrice}
                    setMin={setMinPrice}
                    setMax={setMaxPrice}
                    color={websiteContext?.mainColor}
                  />
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
      <Flex
        width={{ base: '100%', md: '100%', lg: '1000px' }}
        display="grid"
        gridTemplateColumns={{
          base: '49% 49%',
          md: '32.75% 32.75% 32.75%',
          lg: '24.5% 24.5% 24.5% 24.5%'
        }}
        rowGap="7px"
        columnGap={{ base: '2%', md: '0.75%', lg: '0.66%' }}
        justifyItems={{ base: 'center', md: 'center', lg: 'initial' }}
        mt="15px"
        mb="15px"
        p={{ base: '0em 1em', md: '0em 1em', lg: '0em' }}>
        {sortedProducts?.map((p, i) => (
          <ProductThumbnail
            listName={`Page émission TV du ${get(date, '0')}`}
            key={`product_${i}`}
            product={p}
            isList={true}
            defaultPrice={p?.customFields?.standardPrice}
            specialPrice={p?.customFields?.standardPrice - p?.customFields?.discount}
            discount={p?.customFields?.discount}
            currency="€"
          />
        ))}
      </Flex>
      {pageCount > 1 && (
        <Paginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          forcePage={currentPage - 1}
          containerClassName={'pagination'}
          activeClassName={'active'}
          hrefBuilder={hrefBuilder}
          color={websiteContext?.mainColor}
        />
      )}
    </Flex>
  )
}

TVShowPage.pageType = 'productList'

export default TVShowPage
